<script>
import {Bar, mixins} from "vue-chartjs";

export default {
  props: ['chartData', 'options'],
  extends: Bar,
  mixins: [mixins.reactiveProp],
  data() {
    return {};
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
