<script>
import {Line, mixins} from "vue-chartjs";

const {reactiveProp} = mixins;

export default {
  props: ["chartData", "options"],
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {};
  },
  mounted() {
    this.renderChart(this.chartData, this.LineOptions,);

  },
  computed: {
    LineOptions() {
      return {
        maintainAspectRatio: false,
        elements: {
          point: {
            //radius: 0
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                max: this.chartData.maxValue * 1.1,
                min: 0,
              },
            },
          ],
        },
      };
    },
  },
};
</script>
