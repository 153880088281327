<template>
  <v-card
      class="my-4 pa-5 pl-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-5' : 'mx-5'"
  >
    <v-row>
      <v-col order-lg="12" cols="12" lg="4" xl="3">
        <v-tabs
            :vertical="$vuetify.breakpoint.lgAndUp"
            show-arrows
            v-model="tab"
            class="d-flex justify-center"
        >
          <div
              v-for="item in datas"
              :key="item.name"
              class="d-flex justify-center"
          >
            <v-tab class="mb-2">
              {{ item.name }}
            </v-tab>
          </div>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <div v-for="item in datas" :key="item.name">
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pb-0">
                  <v-row justify="space-around">
                    <!-- datepicker -->
                    <v-card flat max-width="150px">
                      <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-left="75"
                          transition="scale-transition"
                          :nudge-bottom="50"
                          min-width="auto"
                          v-if="
                          item.name === 'Activité par jour' ||
                          item.name === 'Utilisateurs activés' ||
                          item.name === 'Utilisateurs activés par jour'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="dateminlocal"
                              label="Date de début"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dateminlocal"
                            @input="
                            menu = false;
                            emitChangedatemin();
                          "
                            :max="datemaxlocal"
                            min="2022-01-01"
                        ></v-date-picker>
                      </v-menu>
                    </v-card>
                    <!-- /datepicker -->
                    <!-- datepicker -->
                    <v-card flat max-width="150px">
                      <v-menu
                          v-model="menufin"
                          :close-on-content-click="false"
                          :nudge-left="$vuetify.breakpoint.lgAndDown ? 75 : 100"
                          transition="scale-transition"
                          :nudge-bottom="50"
                          min-width="auto"
                          v-if="
                          item.name === 'Activité par jour' ||
                          item.name === 'Utilisateurs activés' ||
                          item.name === 'Utilisateurs activés par jour'
                        "
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="datemaxlocal"
                              label="Date de fin"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="datemaxlocal"
                            @input="
                            menufin = false;
                            emitChangedatemax();
                          "
                            :max="
                            new Date(Date.now()).toISOString().substr(0, 10)
                          "
                            :min="dateminlocal"
                        ></v-date-picker>
                      </v-menu>
                    </v-card>
                    <!-- /datepicker -->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-col>
      <v-col order-md="1" cols="12" lg="8" xl="9">
        <v-tabs-items v-model="tab">
          <div v-for="item in datas" :key="item.name">
            <v-tab-item>
              <v-card flat>
                <v-card-text class="display:'flex'" style="text-align: center">
                  <h3 class="mb-4">{{ item.name }}</h3>
                  <v-btn
                      v-if="!item.loaded"
                      :loading="true"
                      elevation="0"
                      color="white"
                      class="ma-2 primary--text"
                  />
                  <BarChart
                      :chartData="item.chartData"
                      :options="BarOptions"
                      v-if="item.type === 'bar' && item.loaded"
                  />
                  <Doughnut
                      :datas="item.chartData"
                      :key="key"
                      v-if="item.type === 'donuts' && item.loaded"
                  />
                  <LineChart
                      :chartData="item.chartData"
                      :key="key"
                      v-if="item.type === 'line' && item.loaded"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import BarChart from "./BarChart.vue";
import Doughnut from "./Doughnut.vue";
import LineChart from "./LineChart.vue";

export default {
  props: ["datas"],
  components: {BarChart, Doughnut, LineChart},

  computed: {
    pageActive() {
      return this.$route.name;
    },
  },
  watch: {
    datas() {
      this.key = !this.key;
    },
  },
  data: () => ({
    key: true,
    tab: [],
    dateminlocal: new Date(
        new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 2)
    )
        .toISOString()
        .substr(0, 10),
    datemaxlocal: new Date(Date.now()).toISOString().substr(0, 10),
    menu: false,
    menufin: false,

    BarOptions: {
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      legend: {
        display: true,
        position: "bottom",
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  methods: {
    emitChangedatemin() {
      this.$emit("changedatemin", {message: this.dateminlocal});
    },
    emitChangedatemax() {
      this.$emit("changedatemax", {message: this.datemaxlocal});
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
