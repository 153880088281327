import http from "/http-common"; ///axios
class StatistiquesService {
	getStatsGenerales(startDate,endDate) {
		return http.get(`/statistique/global?startDate=${startDate}&endDate=${endDate}`);
	}
	postStatsEtablissement(idEtablissement,startDate,endDate) {
		return http.post(`/statistique/etablissement/${idEtablissement}?startDate=${startDate}&endDate=${endDate}`);
	}
	postStatsUtilisateur(id) {
		return http.post(`/statistique/utilisateur/${id}`);
	}

}
export default new StatistiquesService();
