<script>
import {Doughnut} from "vue-chartjs";

export default {
  props: ["datas"],
  extends: Doughnut,
  data() {
    return {
      chartData: this.datas,
      options: {
        legend: {
          display: true,
          position: "bottom",
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }

  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
